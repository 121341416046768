<template>
  <div class=" pt-3 px-3 ">
    <div class="col-12">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation" >
          <button class="nav-link active" id="pagamento-tab" data-bs-toggle="tab" data-bs-target="#pagamento" type="button"
                  role="tab" aria-controls="pagamento" aria-selected="true">
            Controle de pagamento
          </button>
        </li>
        <li class="nav-item" role="presentation" >
          <button class="nav-link" id="pagina-tab" data-bs-toggle="tab" data-bs-target="#pagina" type="button"
                  role="tab" aria-controls="pagina" aria-selected="true">
            Página do treinador
          </button>
        </li>

      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade active show" id="circunferencia" role="tabpanel" aria-labelledby="circunferencia-tab">
          <controle-pagamento />
        </div>
        <div class="tab-pane fade" id="medidas" role="tabpanel" aria-labelledby="medidas-tab">
          <pagina-treinador />
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { reactive, onMounted, ref, inject, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import moment from "moment";
import ControlePagamento from "@/views/pages/treinador/administracao/controlePagamento.vue";
import PaginaTreinador from "@/views/pages/treinador/administracao/paginaTreinador.vue";


export default {
  components: {
    PaginaTreinador,
    ControlePagamento


  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const swal = inject('$swal')
    const store = useStore()

    onMounted( () => {
      if(route.params.id && parseInt(route.params.id) > 0) {
        state.avaliacaoId = parseInt(route.params.id)
      }
      state.assessment_date = moment().format("DD/MM/yyyy")
      state.displayComponent = true
    })


    const state = reactive({
      proccess: false,
      composicaoPrimeira: null,
      composicaoAnterior: null,
      composicaoAtual: null,
      circunferenciaPrimeira: null,
      circunferenciaAnterior: null,
      circunferenciaAtual: null,
      avaliacaoId: 0,
      anamnese: null,
      assessment_date: moment().format("DD/MM/yyyy"),
      assessment_location: '',
    })






    return {
      state
    }
  }
}

</script>