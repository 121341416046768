<template>
  <div class=" pt-3 px-3" v-if="!acessoBloqueado">
    <assina-termo />
    <psr :show="showPsr" @fechaPsrModal="showPsr = false" />
    <pse :show="showPse" @fechaPseModal="salvarTreino" />
    <div class="row">
      <div class="col-md-4 col-12">
        <div class="row">
          <div class="col-12">
            <calendar @selecionaDiaTreino="handleDateClick" />
          </div>
          <div class="col-12 mt-3" v-if="state.dataSelecionada && state.trainingResume != null">
            <h5>Resumo</h5>
            <div class="row text-sm">
              <div class="col-12">
                <span class="text-bold">Total de treinos: </span>
                {{state.trainingResume.total_trainings}}
              </div>
              <div class="col-12">
                <span class="text-bold">Treinos realizados: </span>
                {{state.trainingResume.total_trainings_executed}}

              </div>
              <div class="col-12">
                <span class="text-bold">Treinos não realizados: </span>
                {{state.trainingResume.total_trainings_not_executed}}
              </div>
              <div class="col-12">
                <span class="text-bold">Treinos incompletos: </span>
                {{state.trainingResume.total_trainings_incomplete}}
              </div>
              <div class="col-12">
                <span class="text-bold">
                  Treino de n°:
                </span> {{ state.treinoElaborado[0]['training_number'] }}
              </div>
              <div class="col-12" v-if="state.trainingResumeCaloric != null">
                <span class="text-bold">Gasto calórico do treino: </span>
                {{state.trainingResumeCaloric.training_caloric}}
              </div>
              <div class="col-12" v-if="state.trainingResumeCaloric != null">
                <span class="text-bold">Gasto calórico acumulado: </span>
                {{state.trainingResumeCaloric.plan_accumulated}}
              </div>
              <div class="col-12 mt-2">
                <div class="form-group">
                  <label for="">Observações: </label>
                  <textarea rows="3" class="w-100 form-control" style="resize: none" v-model="state.observation" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8 col-12">
        <div class="row">
          <!-- CARD DE TREINAMENTO DE CARDIO -->
          <div class="col-12">
            <div class="card card-body">
              <div class="row">
                <div class="col-12 text-center">
                  <h5>Treinamento de cardio</h5>
                </div>
                <div class="col">
                  <label>Modalidade</label>
                  <select
                      class="form-control custom-select"
                      name="modalidades"
                      id="select-modalidades"
                      v-model="state.cardio.cardio_modality_id"
                  >
                    <option value="0">Selecione uma modalidade</option>
                    <option v-for="(modalidade, index) in state.modalidades" :key="index" :value="modalidade.id">{{ modalidade.name }}</option>
                  </select>
                </div>
                <div class="col">
                  <label>Método</label>
                  <select
                      class="form-control custom-select"
                      name="grupos"
                      v-model="state.cardio.cardio_method_id"
                  >
                    <option value="0">Selecione um método</option>
                    <option v-for="(modalidade, index) in state.metodos" :key="index" :value="modalidade.id">{{ modalidade.name }}</option>
                  </select>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label class="required">Tempo</label>
                    <div class="input-group">
                      <input placeholder="00:00" type="text" v-maska="'##:##'" class="form-control" v-model="state.cardio.cardio_time"/>
                      <span class="input-group-text" id="basic-addon2">min.</span>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label class="required">Metragem</label>
                    <div class="input-group">
                      <money3 v-bind="state.config" v-model="state.cardio.cardio_distance" class="form-control"></money3>
                      <span class="input-group-text" id="basic-addon2">km</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- TABELA DE LISTA DE EXERCICIOS -->
          <div class="card card-body mt-4 mx-2" v-if="state.dataSelecionada">
            <div class="col-12">
              <div class="row">
                <div class="col-12 h5 mt-3 text-bold text-center">
                  Treino do dia {{state.dataSelecionada.split('-').reverse().join('/')}}
                </div>

                <div class="col-12 table-responsive">
                  <table class="table table-sm">
                    <thead>
                    <tr>
                      <th scope="col" class="p-0"  >Exercício</th>
                      <th scope="col" class="p-0" >Cargas</th>
                      <th>Status</th>
                    </tr>
                    </thead>
                    <tbody class="text-sm" v-if="state.treinoElaborado.length === 0">
                    <tr>
                      <td colspan="8" class="font-weight-bold pt-3 text-decoration-underline text-center">
                        Nenhum exercício para exibir
                      </td>
                    </tr>
                    </tbody>
                    <tbody class="text-sm" v-else>
                      <tr v-for="(element, index) in state.treinoElaborado" :key="index" >
                        <td class="align-middle" style="width: 20%">
                          {{ element.exercise.name }}
                        </td>
                        <td class="d-flex">
                          <table
                              v-for="(load, loadIndex) in element.series"
                              :key="loadIndex"
                          >
                            <tr>
                              <td rowspan="2" style="text-align: center; vertical-align: middle; height: 30px;">
                                <input
                                    class="form-control form-control-sm"
                                    type="text"
                                    v-model="load.load"
                                    v-maska="'###'"
                                    style="width: 46px; height: 65px;"
                                />
                              </td>
                              <td>
                                <input
                                    class="form-control form-control-sm"
                                    type="text"
                                    v-model="load.series"
                                    v-maska="'###'"
                                    style="width: 50px;"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                    class="form-control form-control-sm"
                                    type="text"
                                    v-model="load.repetitions"
                                    v-maska="'###'"
                                    style="width: 50px;"
                                />
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td>
                          <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="element.realized" value="1" @change="verificaTodosRealizados()">
                            <label class="form-check-label" for="flexSwitchCheckDefault">{{ element.realized ? 'Realizado' : 'Não realiz.' }}</label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="3">
                          <div class="float-end">
                            <div class="form-check form-switch" @click="marcarTodos">
                              <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" :checked="state.todosMarcados">
                              <label class="form-check-label" for="flexSwitchCheckDefault">{{ state.todosMarcados ? 'Todos realizados' : 'Todos não realiz.' }}</label>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>

              </div>
            </div>
            <div class="col-12 text-end" v-if="state.dataSelecionada">
              <div class="d-flex form-group justify-content-xl-end text-end">
                <label class="align-self-end me-2">Tempo de treino</label>
                <div class="input-group w-25">
                  <input placeholder="00:00" type="text" v-maska="'##:##'" class="form-control" v-model="state.cardio.training_time"/>
                  <span class="input-group-text" id="basic-addon2">min.</span>
                </div>
              </div>
              <button type="button" class="ms-3 btn bg-gradient-primary" @click="showPse = true">Salvar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, ref, inject, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import calendar from './calendar.vue'
import psr from './psrModal.vue'
import pse from './pseModal.vue'
//import draggable from "vuedraggable";
import kpiRepository from "../../../../services/api/kpiRepository"
import planosTreinoRepository from "../../../../services/api/planosTreinoRepository"
import modalidadeRepository from "@/services/api/modalidadeRepository";
import metodoRepository from "@/services/api/metodoRepository";
import alunoRepository from "@/services/api/alunoRepository";
import { Money3Component } from 'v-money3'
import AssinaTermo from "@/views/pages/treinador/areaAluno/assinaTermo.vue";

export default {
  components: {
    AssinaTermo,
    //draggable,
    calendar,
    psr,
    pse,
    money3: Money3Component
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const swal = inject('$swal')
    const store = useStore()

    onMounted( () => {
      /*if(route.params.id && parseInt(route.params.id) > 0) {
        state.composicaoId = parseInt(route.params.id)
      }
      buscarDadosComposicao()*/
      store.commit('SET_SPLASH_SCREEN', false)
      buscarModalidade()
      buscarMetodos()
      verificaBloqueio()
      let psrNota = window.localStorage.getItem('psr');
      console.log(psrNota)
      if(store.getters.UserType == 's' && psrNota == null) {
        showPsr.value = true
      }
    })

    // let modalidadeSelecionadaId = ref('0')
     let showPsr = ref(false)
     let showPse = ref(false)
     let acessoBloqueado = ref(false)

    const state = reactive({
      todosMarcados: false,
      trainingResumeCaloric: null,
      trainingResume: null,
      dataSelecionada: null,
      treinoElaborado: [],
      exerciciosTestados: [],
      metodos: [],
      modalidades: [],
      observation: '',
      cardio: {
        training_time: null,
        cardio_time: null,
        cardio_distance: '',
        cardio_method_id: 0,
        cardio_modality_id: 0

      },
      config: {
        masked: false,
        prefix: '',
        suffix: '',
        thousands: '.',
        decimal: ',',
        precision: 3,
        disableNegative: false,
        disabled: false,
        min: 0,
        max: null,
        allowBlank: false,
        minimumNumberOfCharacters: 0,
        shouldRound: true,
        focusOnRight: false,
      }
    });

    function handleDateClick (date = null) {
      state.dataSelecionada = date
      if(date == null) return
      store.commit('SET_SPLASH_SCREEN', true)
      planosTreinoRepository.TreinoPorDiaGet(date).then(response => {
        store.commit('SET_SPLASH_SCREEN', false)
        if(response.data.success) {
          state.treinoElaborado = response.data.data.map(item => {
            item['realized'] = parseInt(item['realized']) == 1
            return item
          })
          // verifica se tem algum exercicio e se tem informacao de cardio
          if(state.treinoElaborado.length > 0) {
            let item = state.treinoElaborado[0]

            if(state.treinoElaborado[0]['cardio_modality_id'] != null) {
              state.cardio.cardio_distance = item.cardio_distance
              state.cardio.cardio_modality_id = item.cardio_modality_id
              state.cardio.cardio_time = item.cardio_time
              state.cardio.cardio_method_id = item.cardio_method_id
            }
            state.cardio.training_time = item.training_time
            state.observation = item.observation
            verificaTodosRealizados()
          }
        }
      }).catch(error => {
        store.commit('SET_SPLASH_SCREEN', false)
        console.error(error.message)
        swal({
          icon: 'error',
          title: 'Erro ao buscar treino!',
          text: error.message
        })
      }).finally(() => {
        store.commit('SET_SPLASH_SCREEN', false)
        buscarResumo()
        buscarGastoCalorico()
      })
    }

    function verificaTodosRealizados() {
      let allTrue = true
      state.treinoElaborado.forEach(item => {
        console.log(item['realized'])
        if(!item['realized']) {
          allTrue = false
        }
      })
      state.todosMarcados = allTrue
    }

    const buscarGastoCalorico = () => {
      store.commit('SET_SPLASH_SCREEN', true)
      kpiRepository.CaloricExpenditureGet(state.dataSelecionada).then(response => {
        store.commit('SET_SPLASH_SCREEN', false)
        if (response.data.success) {
          state.trainingResumeCaloric = response.data.data
        }
      }).catch((error) => {
        store.commit('SET_SPLASH_SCREEN', false)
        swal({
          icon: 'error',
          title: 'Erro ao buscar gosto calórico!',
          text: error.message
        })
      }).finally(() => {
        store.commit('SET_SPLASH_SCREEN', false)
      })
    }

    const buscarResumo = () => {
      store.commit('SET_SPLASH_SCREEN', true)
      kpiRepository.TrainingResumeGet().then(response => {
        store.commit('SET_SPLASH_SCREEN', false)
        if (response.data.success) {
          state.trainingResume = response.data.data
        }
      }).catch((error) => {
        store.commit('SET_SPLASH_SCREEN', false)
        swal({
          icon: 'error',
          title: 'Erro ao buscar resumo!',
          text: error.message
        })
      }).finally(() => {
        store.commit('SET_SPLASH_SCREEN', false)
      })
    }

    const buscarModalidade = () => {
      store.commit('SET_SPLASH_SCREEN', true)
      modalidadeRepository.ModalidadeGet().then(response => {
        store.commit('SET_SPLASH_SCREEN', false)
        if (response.data.success) {
          state.modalidades = response.data.data
          /*if(props.modalidadeId > 0) {
            modalidadeSelecionadaId.value = props.modalidadeId
            buscarGrupoPorModalidade()
          }*/
        }
      }).catch((error) => {
        store.commit('SET_SPLASH_SCREEN', false)
        swal({
          icon: 'error',
          title: 'Erro ao buscar modalidade!',
          text: error.message
        })
      })
    }

    const buscarMetodos = () => {
      store.commit('SET_SPLASH_SCREEN', true)
      //selecionaModalidade()
      metodoRepository.MetodoGet().then(response => {
        store.commit('SET_SPLASH_SCREEN', false)
        if (response.data.success) {
          state.metodos = response.data.data
          /*if(props.grupoId > 0) {
            grupoSelecionadoId.value = props.grupoId
          }*/
          //buscarGrupo(state.exercicio.group_id)
        }
      }).catch((error) => {
        store.commit('SET_SPLASH_SCREEN', false)
        swal({
          icon: 'error',
          title: 'Erro ao buscar métodos!',
          text: error.message
        })
      })
    }

    const atualizarExercicio = (index, elementId, elementRealized) => {
      store.commit('SET_SPLASH_SCREEN', true)
      state.treinoElaborado[index].realized = !state.treinoElaborado[index].realized
      //selecionaModalidade()
      let data = {
        realized: state.treinoElaborado[index].realized
      }
      planosTreinoRepository.PlanoTreinoAtualizarExerciciosPorId(elementId, data).then(response => {
        store.commit('SET_SPLASH_SCREEN', false)
        if (response.data.success) {
          swal({
            icon: 'success',
            text: 'Realização registrada com sucesso!'
          })
        } else {
          swal({
            icon: 'error',
            text: 'Não foi possível registrar a execução'
          })
          // se der errado reverte o estado de realização do exercicio
          state.treinoElaborado[index].realized = !state.treinoElaborado[index].realized
        }
      }).catch((error) => {
        // se der errado reverte o estado de realização do exercicio
        state.treinoElaborado[index].realized = !state.treinoElaborado[index].realized
        store.commit('SET_SPLASH_SCREEN', false)
        swal({
          icon: 'error',
          text: 'Não foi possível registrar a execução'
        })
      })
    }

    const salvarTreino = () => {
      let data = []
      let psr = window.localStorage.getItem('psr')
      let pse = window.localStorage.getItem('pse')
      state.treinoElaborado.forEach(obj => {
        let item = obj
        item['observation'] = state.observation
        item['cardio_time'] = state.cardio.cardio_time
        item['cardio_distance'] = state.cardio.cardio_distance
        item['psr'] = psr
        item['training_time'] = state.cardio.training_time
        item['pse'] = pse
        item['cardio_method_id'] = parseInt(state.cardio.cardio_method_id) > 0 ? parseInt(state.cardio.cardio_method_id) : null
        item['cardio_modality_id'] = parseInt(state.cardio.cardio_modality_id) > 0 ? parseInt(state.cardio.cardio_modality_id) : null
        data.push(item)
      })
      // console.log(data)
      // return
      showPse.value = false
      store.commit('SET_SPLASH_SCREEN', true)
      //planosTreinoRepository.SalvarCardioPost(data).then(response => {
      planosTreinoRepository.PlanoTreinoAtualizarExerciciosDiaPost(data).then(response => {
        store.commit('SET_SPLASH_SCREEN', false)
        if (response.data.success) {
          swal({
            icon: 'success',
            text: 'Treino salvo com sucesso!'
          })
        }
      }).catch((error) => {
        store.commit('SET_SPLASH_SCREEN', false)
        swal({
          icon: 'error',
          title: 'Erro ao salvar treino!',
          text: error.message
        })
      })
    }

    function marcarTodos() {
      state.todosMarcados = !state.todosMarcados
      console.log(state.todosMarcados)
      if(state.todosMarcados) {
        state.treinoElaborado.forEach(item => {
          item.realized = true
        })
      } else {
        state.treinoElaborado.forEach(item => {
          item.realized = false
        })
      }
    }

    function verificaBloqueio() {
      alunoRepository.AlunoBloqueadoGet().then(response => {
        if(response.data.success) {
          const data = response.data.data

          acessoBloqueado.value = data.block
          console.log('acessoBloqueado')
          console.log(data)
          if(acessoBloqueado.value) {
            swal({
              icon: 'error',
              text: 'Acesso bloqueado. Contate o seu treinador!'
            })
          } else {
            if((data.remaingDays < 0 && data.remaingDays >= -3)) {
              swal({
                icon: 'info',
                text: 'Não identificamos o pagamento deste mês. Lembre-se de manter seu pagamento em dia!'
              })
            }
            if((data.remaingDays >= 0 && data.remaingDays <= 3)) {
              swal({
                icon: 'info',
                text: 'Sua data de pagamento está próxima. Lembre-se de manter seu pagamento em dia!'
              })
            }
            if(data.remaingDays < -3) {
              swal({
                icon: 'info',
                text: 'Não identificamos o pagamento deste mês. Entre em contato com seu treinador!'
              })
            }
          }

        }
      })
    }


    return {
      state,
      marcarTodos,
      handleDateClick,
      // modalidadeSelecionadaId,
      // metodoSelecionadoId,
      salvarTreino,
      atualizarExercicio,
      showPsr,
      showPse,
      acessoBloqueado,
      verificaTodosRealizados
    }
  }
}
</script>



<style scoped>
.table-responsive {
  max-height: 400px;
}
</style>